<template>
  <div class="p-5 font-size-30">
    <div class="font-weight-bold mb-3">Access Denied</div>
    <div>You do not have access to this page.</div>
    <div class="font-weight-bold font-size-70 mb-1">403 —</div>
    <router-link to="/" class="btn btn-outline-primary width-100"
      >Go Back</router-link
    >
  </div>
</template>
<script>
export default {
  name: "CuiError403",
};
</script>
